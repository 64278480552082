<template>
  <section v-if="Object.keys(pricing).length" id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center">
     <h1 class="mt-5">{{ $t('Pricing_Plans') }}</h1>
<p class="mb-2 pb-75">
  {{ $t('Pricing_Plans_Description') }}
  <br>
    {{ $t('choose_currency') }}

  
        <b-form-group label-for="" class="df-center">
          <v-select
            class="select-price"
            style="
              width: 10%;
              border: none;
              margin-top: 1%;
              border-raduis: 5px;
              padding: 0.5%;
              -webkit-box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
              box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
            "
            v-model="currency"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            label="title"
            :options="currencyList"
            @input="getExchangeRate"
          >
          </v-select>
        </b-form-group>
      </p>
      <!-- <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">Monthly</h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="tooglePlan"
        />
        <h6 class="ml-50 mb-0">Annually</h6>
      </div> -->
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" lg="12" class="mx-auto">
        <b-row>
          <b-col md="3">
            <b-card align="center">
              <!-- img -->
              <b-img
                v-if="pricing.basicPlan.img"
                :src="pricing.basicPlan.img"
                class="mb-2 mt-5"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    v-if="currencySymbol != 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.basicPlan.monthlyPrice
                        : pricing.basicPlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sup
                    v-if="currencySymbol == 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/month</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} /
                  year</small
                >
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <!-- <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="outline-success"
              >
                Your current plan
              </b-button> -->
              <b-form-group label-for="basicInput">
                <b-form-input
                  id="basicInput"
                  placeholder="Voucher: XYZ-XYZ-XYZ"
                />
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click="preparePayment(pricing.basicPlan.monthlyPrice, 'basicPlan')"
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card class="popular" align="center">
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge variant="light-primary" pill> Popular </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    v-if="currencySymbol != 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.standardPlan.monthlyPrice
                        : pricing.standardPlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sup
                    v-if="currencySymbol == 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/month</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >USD {{ pricing.standardPlan.yearlyPlan.totalAnual }} /
                  year</small
                >
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->
              <b-form-group label-for="basicInput">
                <b-form-input
                  id="basicInput"
                  placeholder="Voucher: XYZ-XYZ-XYZ"
                />
              </b-form-group>

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click="
                  preparePayment(pricing.enterprisePlan.monthlyPrice * 12, 'standardPlan')
                "
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card align="center">
              <!-- img -->
              <b-img
                v-if="pricing.enterprisePlan.img"
                :src="pricing.enterprisePlan.img"
                class="mb-2"
                alt="enterprise svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.enterprisePlan.title }}</h3>
              <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    v-if="currencySymbol != 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.enterprisePlan.monthlyPrice
                        : pricing.enterprisePlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sup
                    v-if="currencySymbol == 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/month</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >USD {{ pricing.enterprisePlan.yearlyPlan.totalAnual }} /
                  year</small
                >
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data, index) in pricing.enterprisePlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <b-form-group label-for="basicInput">
                <b-form-input
                  id="basicInput"
                  placeholder="Voucher: XYZ-XYZ-XYZ"
                />
              </b-form-group>

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
                @click="preparePayment(pricing.enterprisePlan.monthlyPrice * 6, 'enterprisePlan')"
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card align="center">
              <!-- img -->
              <b-img
                v-if="pricing.enterpriseSuperPlan.img"
                :src="pricing.enterpriseSuperPlan.img"
                class="mb-2"
                alt="enterprise svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.enterpriseSuperPlan.title }}</h3>
              <b-card-text>{{
                pricing.enterpriseSuperPlan.subtitle
              }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    v-if="currencySymbol != 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{
                      monthlyPlanShow
                        ? pricing.enterpriseSuperPlan.monthlyPrice
                        : pricing.enterpriseSuperPlan.yearlyPlan.perMonth
                    }}</span
                  >
                  <sup
                    v-if="currencySymbol == 'DT'"
                    class="font-medium-1 font-weight-bold text-primary"
                    >{{ currencySymbol }}</sup
                  >
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                    >/User</sub
                  >
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                  >USD {{ pricing.enterpriseSuperPlan.yearlyPlan.totalAnual }} /
                  year</small
                >
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data, index) in pricing.enterpriseSuperPlan
                  .planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->
              <b-form-group label-for="basicInput">
                <b-form-input
                  v-model="numberOfUsers"
                  min="5"
                  type="number"
                  id="basicInput"
                  placeholder="Number of users (min 5)"
                  @change="numberOfUser()"
                />
              </b-form-group>
              <b-form-group label-for="basicInput">
                <b-form-input
                  id="basicInput"
                  placeholder="Voucher: XYZ-XYZ-XYZ"
                />
              </b-form-group>

              <!-- buttons -->
              <b-button
                v-if="numberOfUsers != null && numberOfUsers >= 5"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
                @click="
                  preparePayment(
                    pricing.enterpriseSuperPlan.monthlyPrice *
                      numberOfUsers *
                      12 , 'premiumPlan'
                  )
                "
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Still not convinced? Start with a 15-day FREE trial!
              </h3>
              <h5>
                You will get full access to with all the features for 15 days.
              </h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                @click="paymentOffer('free', 'trial')"
              >
                Start 15-day FREE trial
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <!-- <div class="pricing-faq">
      <h3 class="text-center">FAQ's</h3>
      <p class="text-center">Let us help answer the most common questions.</p>
      <b-row class="py-2">
        <b-col lg="10" offset-lg="2" class="mx-auto">
          <app-collapse accordion type="margin">
            <app-collapse-item
              v-for="(data, index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/* eslint-disable global-require */
export default {
  components: {
    vSelect,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
        ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      numberOfUsers: null,
      exchangeRate: {},
      status: "monthly",
      monthlyPlanShow: true,
      pricing: {},
      currency: { title: "USD", currency: "$" },
      // currencyList: ["USD", "EUR", "TND"],
      currencyList: [
        { title: "USD", currency: "$" },
        { title: "EUR", currency: "€" },
        { title: "TND", currency: "DT" },
      ],
      currencySymbol: "$",
      pricing: {
        basicPlan: {
          title: this.$t('basic'),
          img: require("@/assets/images/illustration/Pot1.svg"),
          subtitle: "Billed every month",
          monthlyPrice: 3.99,
          yearlyPlan: {
            perMonth: 0,
            totalAnual: 0,
          },
          planBenefits: [
            "Calendar more available views",
            "To-Do-list with unlimited assignments and attachments",
            "Unlimited Meetings",
            "Unlimited Goals",
            "Unlimited Project",
          ],
          popular: false,
        },
        enterprisePlan: {
          title: "Professional",
          img: require("@/assets/images/illustration/Pot2.svg"),
          subtitle: "Billed every 12 months",
          monthlyPrice: 1.99,
          yearlyPlan: {
            perMonth: 40,
            totalAnual: 480,
          },
          planBenefits: [
            "Calendar more available views",
            "To-Do-list with unlimited assignments and attachments",
            "Unlimited Meetings",
            "Unlimited Goals",
            "Unlimited Project",
          ],
          popular: true,
        },
        standardPlan: {
          title: "Standard",
          img: require("@/assets/images/illustration/Pot3.svg"),
          subtitle: "Billed every 6 months",
          monthlyPrice: 2.99,
          yearlyPlan: {
            perMonth: 80,
            totalAnual: 960,
          },
          planBenefits: [
            "Calendar more available views",
            "To-Do-list with unlimited assignments and attachments",
            "Unlimited Meetings",
            "Unlimited Goals",
            "Unlimited Project",
          ],
          popular: false,
        },
        enterpriseSuperPlan: {
          title: "Entreprise",
          img: require("@/assets/images/illustration/Pot3.svg"),
          subtitle: "Billed every 12 months",
          monthlyPrice: 1.99,
          yearlyPlan: {
            perMonth: 80,
            totalAnual: 960,
          },
          planBenefits: [
            "Calendar more available views",
            "To-Do-list with unlimited assignments and attachments",
            "Unlimited Meetings",
            "Unlimited Goals",
            "Unlimited Project",
          ],
          popular: false,
        },
        qandA: [
          {
            question: "Does my subscription automatically renew?",
            ans: "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
          },
          {
            question:
              "Can I store the item on an intranet so everyone has access?",
            ans: "Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.",
          },
          {
            question: "Am I allowed to modify the item that I purchased?",
            ans: "Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.",
          },
        ],
      },
    };
  },
  async created() {
    await this.getCurrency();
    await this.getExchangeRate();
    if (this.$route.query.success) {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "You have activated your offer",
      //     icon: "EditIcon",
      //     variant: "success",
      //   },
      // });
      this.paymentOffer(this.$route.query.amount, this.$route.query.type)
    } else if (this.$route.query.cancel) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "You have canceled the payment",
          icon: "EditIcon",
          variant: "danger",
        },
      });
    }
    // await this.$http.get("/pricing/data").then((res) => {
    //   this.pricing = res.data;
    // });
  },
  methods: {
    paymentOffer(amount, type) {
      axios
        .post(
          "https://backendapinodejs.timecheckit.com/api/payments",
          {
            offreType: type,
            amountPaid: amount,
            user: JSON.parse(localStorage.getItem("userData"))._id,
            paymentDate: new Date(),
            state: "true",
            numberOfVouchers:this.numberOfUsers || 0
          },
        )
        .then((response) => {
         this.$toast({
            component: ToastificationContent,
            props: {
              title: "You have activated your offer",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    numberOfUser() {
      if (this.numberOfUsers < 5) {
        this.numberOfUsers = 5;
      }
    },
    async getExchangeRate() {
      //  axios
      //   .get(
      //     "https://openexchangerates.org/api/latest.json?app_id=5d0f971872674d5395ef72802b13d7f9"
      //   )
      //   .then((response) => {
      //     console.log("price", response);
      //   })
      //   .catch((error) => {
      //     console.error("There was an error!", error);
      //   });
      console.log(this.currency);
      await axios
        .get("https://api.exchangerate-api.com/v4/latest/USD")
        .then((response) => {
          this.exchangeRate = response.data.rates;
          this.currencySymbol = this.currency.currency;
          if (this.currency.title !== "USD") {
            if (this.currency.title == "TND") {
              this.pricing.basicPlan.monthlyPrice = (
                3.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
              this.pricing.enterprisePlan.monthlyPrice = (
                1.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
              this.pricing.enterpriseSuperPlan.monthlyPrice = (
                1.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
              this.pricing.standardPlan.monthlyPrice = (
                2.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
            } else {
              this.pricing.basicPlan.monthlyPrice = (
                3.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
              this.pricing.enterprisePlan.monthlyPrice = (
                1.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
              this.pricing.enterpriseSuperPlan.monthlyPrice = (
                1.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
              this.pricing.standardPlan.monthlyPrice = (
                2.99 * this.exchangeRate[this.currency.title]
              ).toFixed(0);
            }
          } else {
            this.pricing.basicPlan.monthlyPrice = 3.99;
            this.pricing.enterprisePlan.monthlyPrice = 1.99;
            this.pricing.enterpriseSuperPlan.monthlyPrice = 1.99;
            this.pricing.standardPlan.monthlyPrice = 2.99;
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async getCurrency() {
      await axios
        .get(
          "https://api.ipdata.co?api-key=4c2784853ff1cbf16f867a26b9582961f5767a273ab025d38b9bb11d&fields=currency"
        )
        .then((response) => {
          this.currencySymbol = response.data.currency.symbol;
          // this.currency = response.data.currency.code;
          this.currency = {
            title: response.data.currency.code,
            currency: response.data.currency.symbol,
          };
          if (
            this.currency.title != "TND" &&
            this.currency.title != "USD" &&
            this.currency.title != "EUR"
          ) {
            this.currency.title = "USD";
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    preparePayment(price, offerType) {
      var userData = JSON.parse(localStorage.getItem("userData"));
      console.log(userData);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 5257a7508bace097ffc4ba7208391ad448c6ab96",
        },
      };
      axios
        .post(
          "https://app.paymee.tn/api/v2/payments/create",
          {
            amount: price,
            note: "Order",
            first_name: userData.name,
            last_name: userData.name,
            email: userData.email,
            phone: userData.mobile,
            return_url: `https://timecheck-dashboard.onrender.com/pages/pricing?success='true'&amount=${price}&type=${offerType}&id=${JSON.parse(localStorage.getItem("userData"))._id}`,
            cancel_url:
              "https://timecheck-dashboard.onrender.com/pages/pricing?cancel='true'",
            webhook_url: `https://timecheck-dashboard.onrender.com/pages/pricing?success='true'&amount=${price}&type=${offerType}&id=${JSON.parse(localStorage.getItem("userData"))._id}`,
          },
          axiosConfig
        )
        .then((response) => {
          location.href = response.data.data.payment_url;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      // axios
      //   .post("https://backendapinodejs.timecheckit.com/api/user/stripe", {
      //     amount: 50,
      //     linkCancel: "https://timecheck-dashboard.onrender.com/pages/pricing",
      //     name: "Time Project",
      //     message:
      //       "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page",
      //   })
      //   .then((response) => {
      //     this.session_id = response.data.id;
      //     return this.$stripe.redirectToCheckout({
      //       sessionId: this.session_id,
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("There was an error!", error);
      //   });
    },
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-select.scss";

@media screen and (max-width: 768px) {
  .select-price {
    width: 100% !important;
  }
}
</style>